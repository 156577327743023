exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-solutions-ai-js": () => import("./../../../src/pages/solutions/ai.js" /* webpackChunkName: "component---src-pages-solutions-ai-js" */),
  "component---src-pages-solutions-cloud-js": () => import("./../../../src/pages/solutions/cloud.js" /* webpackChunkName: "component---src-pages-solutions-cloud-js" */),
  "component---src-pages-solutions-iot-js": () => import("./../../../src/pages/solutions/iot.js" /* webpackChunkName: "component---src-pages-solutions-iot-js" */),
  "component---src-pages-solutions-native-js": () => import("./../../../src/pages/solutions/native.js" /* webpackChunkName: "component---src-pages-solutions-native-js" */),
  "component---src-pages-solutions-seo-js": () => import("./../../../src/pages/solutions/seo.js" /* webpackChunkName: "component---src-pages-solutions-seo-js" */),
  "component---src-pages-solutions-vr-js": () => import("./../../../src/pages/solutions/vr.js" /* webpackChunkName: "component---src-pages-solutions-vr-js" */)
}

